import React, {useEffect, useRef, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import Rating from "@mui/material/Rating";
import InnerImageZoom from "react-inner-image-zoom";
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
import Slider from "react-slick";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {Button} from "@mui/material";
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import Product from "../../components/product";
import axios from "axios";
import QuantityBox from "../../components/quantityBox";

const DetailsPage = (props) => {

    const [zoomImage, setZoomImage] = useState('https://www.jiomart.com/images/product/original/491187309/good-life-whole-moong-500-g-product-images-o491187309-p491187309-0-202308311426.jpg');
    const [bigImageSize, setBigImageSize] = useState([1500, 1500]);
    const [smlImageSize, setSmlImageSize] = useState([150, 150]);
    const [activeSize, setActiveSize] = useState(0);

    const [inputValue, setInputValue] = useState(1);
    const [activeTabs, setActiveTabs] = useState(0);
    const [currentProduct, setCurrentProduct] = useState([]);
    const [relatedProducts, setRelatedProducts] = useState([]);

    const [reviewsArr, setReviewsArr] = useState([]);

    const [prodCat, setProdCat] = useState({
        parentCat: sessionStorage.getItem('parentCat'),
        subCatName: sessionStorage.getItem('subCatName')
    })
    const [rating, setRating] = useState(0.0);
    const [reviewFields, setReviewFields] = useState({
        review: '',
        userName: '',
        rating: 0.0,
        productId: 0,
        date: ''
    })

    let {id} = useParams();

    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        fade: false,
        arrows: true
    };
    var settings2 = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: false,
        arrows: false,
        autoplay: 1000
    };

    var related = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        fade: false,
        arrows: true,
    };

    const zoomSlider = useRef();
    const zoomSliderBig = useRef();
    const goto = (index) => {
        zoomSlider.current.slickGoTo(index);
        zoomSliderBig.current.slickGoTo(index);
    }

    const isActive = (index) => {
        setActiveSize(index);
    }

    const plus = () => {
        setInputValue(inputValue + 1);
    }

    const minus = () => {
        if (inputValue != 0) {
            setInputValue(inputValue - 1);
        }
    }

    useEffect(() => {
        window.scroll(0, 0);
        props.data.length !== 0 &&
        props.data.map((item) => {
            item.items.length !== 0 &&
            item.items.map((item_) => {
                item_.products.length !== 0 &&
                item_.products.map((product) => {
                    if (parseInt(product.id) === parseInt(id)) {
                        setCurrentProduct(product);
                    }
                })
            })
        })


        //related products code
        const related_products = [];
        props.data.length !== 0 &&
        props.data.map((item) => {
            if (prodCat.parentCat === item.cat_name) {
                item.items.length !== 0 &&
                item.items.map((item_) => {
                    if (prodCat.subCatName === item_.cat_name) {
                        item_.products.length !== 0 &&
                        item_.products.map((product, index) => {
                            if (product.id !== parseInt(id)) {
                                related_products.push(product)
                            }

                        })
                    }
                })
            }

        })


        if (related_products.length !== 0) {
            setRelatedProducts(related_products)
        }
        showReviews();
    }, [id]);


    const changeInput = (name, value) => {
        if (name === "rating") {
            setRating(value);
        }
        setReviewFields(() => ({
            ...reviewFields,
            [name]: value,
            productId: id,
            date: new Date().toLocaleString()
        }))


    }

    const reviews_Arr = [];
    const submitReview = async (e) => {
        e.preventDefault();
        try {
            await axios.post('http://localhost:5000/productReviews', reviewFields).then((response) => {
                reviews_Arr.push(response.data);
                setReviewFields(() => ({
                    review: '',
                    userName: '',
                    rating: 0.0,
                    productId: 0,
                    date: ''
                }))
            })
        } catch (e) {

        }
        showReviews();
    }

    var reviews_Arr2 = [];
    const showReviews = async () => {
        try {
            await axios.get("http://localhost:5000/productReviews").then((response) => {

                if (response.data.length !== 0) {
                    response.data.map((item) => {
                        if (parseInt(item.productId) === parseInt(id)) {
                            reviews_Arr2.push(item)
                        }

                    })

                }
            })
        } catch (error) {
            console.log(error.message);
        }

        if (reviews_Arr2.length !== 0) {

            setReviewsArr(reviews_Arr2);
        }
    }


    return (
        <>
            <section className="detailsPage mb-5">
                <div className="breadcrumbWrapper2">
                    <div className="container-fluid">
                        <ul className="breadcrumb breadcrumb2 mb-0">
                            <li><Link to={'/'}>Home</Link></li>
                            <li><Link to={`/cat/${prodCat.parentCat.split(' ').join('-').toLowerCase()}`}
                                      onClick={() => sessionStorage.setItem('cat', prodCat.parentCat.split(' ').join('-').toLowerCase())}
                                      className='text-capitalize'>{prodCat.parentCat}</Link></li>

                            <li><Link
                                to={`/cat/${prodCat.parentCat.toLowerCase()}/${prodCat.subCatName.replace(/\s/g, '-').toLowerCase()}`}
                                onClick={() => sessionStorage.setItem('cat', prodCat.subCatName.toLowerCase())}
                                className='text-capitalize'>{prodCat.subCatName}</Link></li>
                            <li>{currentProduct.productName}</li>
                        </ul>
                    </div>
                </div>
                <div className="container detailsContainer pt-3 pb-3">


                    <div className="row">
                        {/*productZoom code start here*/}
                        <div className="col-md-5">
                            <div className="productZoom">
                                <Slider {...settings2} className='zoomSliderBig' ref={zoomSliderBig}>
                                    {
                                        currentProduct.productImages !== undefined &&
                                        currentProduct.productImages.map((imgUrl, index) => {
                                            return (
                                                <div className="item" key={index}>
                                                    <InnerImageZoom zoomType="hover" zoomScale={1}
                                                                    src={`${imgUrl}?im=Resize=(${bigImageSize[0]},${bigImageSize[1]})`}/>
                                                </div>
                                            )
                                        })
                                    }
                                </Slider>
                            </div>
                            <Slider {...settings} className='zoomSlider' ref={zoomSlider}>
                                {
                                    currentProduct.productImages !== undefined &&
                                    currentProduct.productImages.map((imgUrl, index) => {
                                        return (
                                            <div className='item' key={index}>
                                                <img src={`${imgUrl}?im=Resize=(${smlImageSize[0]},${smlImageSize[1]})`}
                                                     className='w-100'
                                                     onClick={() => goto(index)}/>
                                            </div>
                                        )
                                    })
                                }
                            </Slider>
                        </div>
                        {/*productZoom code ends here*/}

                        <div className="col-md-7 productInfo">
                            <h1>
                                {currentProduct.productName}
                            </h1>
                            <div className="d-flex align-items-center mb-4">
                                <Rating name="half-rating-read" value={parseFloat(currentProduct.rating)}
                                        precision={0.5} readOnly/>
                                <span className="text-light">(32 reviews)</span>
                            </div>
                            <div className="priceSec d-flex align-items-center">
                                <span className="text-g priceLarge">Rs {currentProduct.price}</span>
                                <div className="ml-2 d-flex flex-column">
                                    <span className="text-org">{currentProduct.discount}% Off</span>
                                    <span className="text-light oldPrice">Rs {currentProduct.oldPrice}</span>
                                </div>
                            </div>

                            <p>{currentProduct.description}</p>
                            {currentProduct.weight !== undefined && currentProduct.weight.length !== 0 &&
                                <div className="productSize d-flex align-items-center">
                                    <span>Size / Weight:</span>
                                    <ul className="list list-inline mb-0 ps-4">
                                        {
                                            currentProduct.weight.map((item, index) => {
                                                return (<li className="list-inline-item" key={index}><a
                                                        className={`tag ${activeSize === index ? 'active' : ''}`}
                                                        onClick={() => isActive(index)}>{item}g</a></li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            }
                            {
                                currentProduct.RAM !== undefined && currentProduct.RAM.length !== 0 &&
                                <div className='productSize d-flex align-items-center'>
                                    <span>RAM:</span>
                                    <ul className='list list-inline mb-0 ps-4'>
                                        {
                                            currentProduct.RAM.map((RAM, index) => {
                                                return (
                                                    <li className='list-inline-item' key={index}><a
                                                        className={`tag ${activeSize === index ? 'active' : ''}`}
                                                        onClick={() => isActive(index)}>{RAM} GB</a></li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            }

                            {
                                currentProduct.SIZE !== undefined && currentProduct.SIZE.length !== 0 &&
                                <div className='productSize d-flex align-items-center'>
                                    <span>SIZE:</span>
                                    <ul className='list list-inline mb-0 ps-4'>
                                        {
                                            currentProduct.SIZE.map((SIZE, index) => {
                                                return (
                                                    <li className='list-inline-item' key={index}><a
                                                        className={`tag ${activeSize === index ? 'active' : ''}`}
                                                        onClick={() => isActive(index)}>{SIZE}</a></li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            }
                            <div className="d-flex align-items-center">
                                <div>
                                <QuantityBox/>
                                </div>
                                <div className="d-flex align-items-center">
                                    <Button className='btn-g btn-lg addtocartbtn'><ShoppingCartOutlinedIcon/> Add To
                                        Cart</Button>
                                    <Button
                                        className='btn-lg addtocartbtn ms-3 btn-border'><FavoriteBorderOutlinedIcon/></Button>
                                    <Button
                                        className='btn-lg addtocartbtn ms-3 btn-border'><CompareArrowsIcon/></Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card mt-5 p-5 detailsPageTabs">
                        <div className="customTabs">
                            <ul className="list list-inline">
                                <li className="list-inline-item">
                                    <Button className={`${activeTabs === 0 && 'active'}`}
                                            onClick={() => setActiveTabs(0)}>Description</Button>
                                </li>
                                <li className="list-inline-item">
                                    <Button className={`${activeTabs === 1 && 'active'}`}
                                            onClick={() => setActiveTabs(1)}>Additional Info</Button>
                                </li>
                                <li className="list-inline-item">
                                    <Button className={`${activeTabs === 2 && 'active'}`}
                                            onClick={() => {
                                                setActiveTabs(2)
                                                showReviews()
                                            }
                                            }>Reviews ({reviewsArr.length})</Button>
                                </li>
                            </ul>
                            <br/>
                            {
                                activeTabs === 0 &&

                                <div className="tabContent">
                                    <p>
                                        {currentProduct.description}
                                    </p>
                                </div>
                            }

                            {
                                activeTabs === 1 &&

                                <div className="tabContent">
                                    <div className="table-responsive">
                                        <table className="table table-bordered">
                                            <tbody>
                                            <tr>
                                                <td>Stand Up</td>
                                                <td>Stand Up</td>
                                            </tr>
                                            </tbody>

                                        </table>
                                    </div>
                                </div>
                            }
                            {
                                activeTabs === 2 &&

                                <div className="tabContent">
                                    <div className="row">
                                        <div className="col-md-8">
                                            <h3>Customer questions & answers</h3>
                                            <br/>

                                            {
                                                reviewsArr.length !== 0 && true &&
                                                reviewsArr.map((item, index) => {
                                                    return (
                                                        <div className="card p-4 reviewsCard flex-row" key={index}>
                                                            <div className="image">
                                                                <div className="rounded-circle">
                                                                    <img
                                                                        src="https://wp.alithemes.com/html/nest/demo/assets/imgs/blog/author-2.png"
                                                                        alt=""/>
                                                                </div>
                                                                <span
                                                                    className="text-g d-block text-center font-weight-bold">
                                                                    {item.userName}
                                                                </span>
                                                            </div>
                                                            <div className="info ps-4">
                                                                <div className="d-flex align-items-center">
                                                                    <h5 className="text-light">{item.date}</h5>
                                                                    <div className="ms-auto">
                                                                        <Rating name="half-rating-read"
                                                                                value={parseFloat(item.rating)}
                                                                                precision={0.5} readOnly/>
                                                                    </div>
                                                                </div>
                                                                <p>{item.review}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }


                                            <br/>
                                            <br/>
                                            <form className="reviewForm" onSubmit={submitReview}>
                                                <h4>Add a review</h4>
                                                <div className="form-group">
                                                    <textarea name="review" className="form-control" id=""
                                                              value={reviewFields.review}
                                                              onChange={(e) => changeInput(e.target.name, e.target.value)}
                                                              placeholder="Write a review"></textarea>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <input type="text" className="form-control"
                                                                   onChange={(e) => changeInput(e.target.name, e.target.value)}
                                                                   value={reviewFields.userName} placeholder="Name"
                                                                   name="userName"/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className='form-group'>
                                                            <Rating name="rating" value={rating} precision={0.5}
                                                                    onChange={(e) => changeInput(e.target.name, e.target.value)}
                                                            />
                                                        </div>
                                                    </div>

                                                    <br/>
                                                    <div className="form-group">
                                                        <Button type="submit" className="btn-g btn-lg">Submit
                                                            Review</Button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="col-md-4">
                                            <h4>Customer reviews</h4>
                                            <div className="d-flex align-items-center mt-2">

                                                <Rating name="half-rating-read" defaultValue={4.5} precision={0.5}
                                                        readOnly/>
                                                <strong className="ms-3">4.8 out of 5</strong>
                                            </div>

                                            <br/>
                                            <div className="progressBarBox d-flex align-items-center">
                                                <span className="me-3">5 star</span>
                                                <div className="progress" style={{width: '85%', height: '20px'}}>
                                                    <div className="progress-bar bg-success"
                                                         style={{width: '80%', height: '20px'}}>80%
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="progressBarBox d-flex align-items-center">
                                                <span className="me-3">4 star</span>
                                                <div className="progress" style={{width: '85%', height: '20px'}}>
                                                    <div className="progress-bar bg-success"
                                                         style={{width: '75%', height: '20px'}}>75%
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="progressBarBox d-flex align-items-center">
                                                <span className="me-3">3 star</span>
                                                <div className="progress" style={{width: '85%', height: '20px'}}>
                                                    <div className="progress-bar bg-success"
                                                         style={{width: '55%', height: '20px'}}>55%
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="progressBarBox d-flex align-items-center">
                                                <span className="me-3">2 star</span>
                                                <div className="progress" style={{width: '85%', height: '20px'}}>
                                                    <div className="progress-bar bg-success"
                                                         style={{width: '50%', height: '20px'}}>35%
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="progressBarBox d-flex align-items-center">
                                                <span className="me-3">1 star</span>
                                                <div className="progress" style={{width: '85%', height: '20px'}}>
                                                    <div className="progress-bar bg-success"
                                                         style={{width: '25%', height: '20px'}}>25%
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            }
                        </div>
                    </div>
                    <br/>
                    <div className="relatedProducts pt-5 pb-4">
                        <h2 className="hd mb-0 mt-0">Related Products</h2>
                        <br/>
                        <Slider {...related} className='prodSlider'>
                            {
                                relatedProducts.length !== 0 &&
                                relatedProducts.map((product, index) => {
                                    return (
                                        <div className="item" key={index}>
                                            <Product tag={product.type} item={product}/>
                                        </div>
                                    )
                                })
                            }
                        </Slider>
                    </div>

                </div>
            </section>
        </>
    )
}

export default DetailsPage;