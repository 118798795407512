import React, {useEffect, useState} from "react";
import './style.css';
import Rating from '@mui/material/Rating';
import {Button} from "@mui/material";
import {Link} from "react-router-dom";
import ShoppingCartOutlined from "@mui/icons-material/ShoppingCartOutlined";
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import CompareArrowsOutlinedIcon from '@mui/icons-material/CompareArrowsOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';

import {MyContext} from '../../App';
import {useContext} from "react";

const Product = (props) => {
    const [productData, setProductData] = useState();
    const [isAdded, setIsAdded] = useState(false);


    const context=useContext(MyContext);

    useEffect(() => {
        setProductData(props.item)
    }, [props.item]);

    const setProductCat=()=>{
        sessionStorage.setItem('parentCat',productData.parentCatName);
        sessionStorage.setItem('subCatName',productData.subCatName);
    }
    const addToCart=(item)=>{
        context.addToCart(item);
        setIsAdded(true);
    }
    return (
        <div className="productThumb" onClick={setProductCat}>
            {
                props.tag !== null && props.tag !== undefined &&
                <span className={`badge ${props.tag}`}>{props.tag}</span>
            }

            {
                productData !== undefined &&
                <>
                    <Link to={`/product/${productData.id}`}>
                        <div className="imgWrapper">
                            <div className="p-4 wrapper">
                                <img src={productData.catImg + '?im=Resize=(420,420)'}
                                     className="w-100"
                                     alt=""/>
                            </div>
                            <div className="overlay transition">
                                <ul className="list list-inline mb-0">
                                    <li className="list-inline-item">
                                        <a className="cursor"
                                           tooltip="Add to Wishlist"><FavoriteBorderOutlinedIcon/></a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a className="cursor" tooltip="Compare"><CompareArrowsOutlinedIcon/></a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a className="cursor" tooltip="Quick View"><RemoveRedEyeOutlinedIcon/></a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </Link>


                    <div className="info">
                        <span className="d-block catName">{productData.brand}</span>
                        <h4 className="title"><Link>{productData.productName.substr(0,70)+'...'}</Link></h4>
                        <Rating name="half-rating-read" value={parseFloat(productData.rating)} precision={0.5} readOnly/>
                        <span className="brand d-block text-g">By <a className='text-g'>{productData.brand}</a> </span>

                        <div className="d-flex align-items-center mt-3">
                            <div className="d-flex align-items-center w-100">
                                <span className="price text-g fw-bold">Rs {productData.price}</span> <span
                                className="oldPrice ms-auto">Rs {productData.oldPrice}</span>
                            </div>


                        </div>
                        <Button className='bg-g mt-3 w-100 transition' onClick={()=>addToCart(productData)}><ShoppingCartOutlined/>
                            {
                                isAdded===true?'Added':'Add'
                            }
                        </Button>
                    </div>
                </>
            }
        </div>
    );
}

export default Product;