import React, {useEffect, useState} from 'react';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import Checkbox from '@mui/material/Checkbox';
import {Button, FormControlLabel, RadioGroup} from "@mui/material";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import bannerImg from '../../assets/images/banner1.jpg';
import {Link, useParams} from "react-router-dom";
import Radio from '@mui/material/Radio';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

function valuetext(value) {
    return `${value}C`;
}

const label = {inputProps: {'aria-label': 'Checkbox demo'}};
const Sidebar = (props) => {
    const [value, setValue] = React.useState([20, 100000]);
    const [value2, setValue2] = useState(0);
    const [totalLength, settotalLength] = useState([]);
    const [brandFilters, setBrandFilters] = useState([]);
    const [ratingsArr, setRatings] = useState([]);

    let {id} = useParams();
    var catLength = 0;
    var lengthArr = [];
    var brands = [];
    var ratings = [];

    useEffect(() => {
        props.data.length !== 0 &&
        props.data.map((item, index) => {
            item.items.length !== 0 &&
            item.items.map((item_) => {
                catLength += item_.products.length
            })
            lengthArr.push(catLength)
            catLength = 0;
        })

        const list = lengthArr.filter((item, index) => lengthArr.indexOf(item) === index);
        settotalLength(list);
    }, []);

    useEffect(() => {
        var price = 0;
        props.currentCatData.length !== 0 &&
        props.currentCatData.map((item, index) => {
            let prodPrice = parseInt(item.price.toString().replace(/,/g, ""));
            if (prodPrice > price) {
                price = prodPrice;
            }
        })
        setValue2(price);
    }, []);

    useEffect(() => {
        props.filterByPrice(value[0], value[1]);
    }, [value]);


    useEffect(() => {
        brands = [];
        ratings = [];
        props.currentCatData.length !== 0 &&
        props.currentCatData.map((item, index) => {
            brands.push(item.brand);
            ratings.push(parseFloat(item.rating));

        })
        const brandList = brands.filter((item, index) => brands.indexOf(item) === index);
        setBrandFilters(brandList);

        const ratings_ = ratings.filter((item, index) => ratings.indexOf(item) === index);
        setRatings(ratings_);

    }, [id]);

    const filterByBrand = (keyword) => {
        props.filterByBrand(keyword);
    }
    const filterByRating = (keyword) => {
        props.filterByRating(parseFloat(keyword));
    }
    return (
        <>
            <div className="sidebar">
                <div className="card border-0 shadow">
                    <h3>Category</h3>
                    <div className="catList">
                        {
                            props.data.length !== 0 && props.data.map((item, index) => {
                                return (
                                    <Link to={`/cat/${item.cat_name.toLowerCase()}`}>
                                        <div className="catItem d-flex align-items-center">
                            <span className="img"><img
                                src="https://wp.alithemes.com/html/nest/demo/assets/imgs/theme/icons/category-1.svg"
                                alt="category" width={30}/></span>
                                            <h4 className="mb-0 ms-3 me-3 text-capitalize">
                                                {item.cat_name}
                                            </h4>
                                            <span
                                                className="d-flex align-items-center justify-content-center rounded-circle ms-auto">{totalLength[index]}</span>
                                        </div>
                                    </Link>
                                )
                            })
                        }
                    </div>
                </div>

                <div className="card border-0 shadow">
                    <h3>Fill by price</h3>
                    <RangeSlider value={value} onInput={setValue} min={100} max={100000} step={5}/>

                    <div className="d-flex pt-2 pb-2 priceRange">
                        <span>From: <strong className='text-success'>Rs.{value[0]}</strong> </span>
                        <span className="ms-auto">From: <strong className='text-success'>Rs. {value[1]}</strong> </span>
                    </div>

                    <div className="filters">
                        <h5>Filter By Brand</h5>
                        <ul className="mb-0">
                            <RadioGroup aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue="female"
                                        name="radio-buttons-group">
                                {
                                    brandFilters.length!==0 &&
                                    brandFilters.map((item,index)=>{
                                        return (
                                            <li key={index}><FormControlLabel value={item} control={<Radio onChange={()=>filterByBrand(item)}/>}
                                                                  label={item}/>
                                            </li>
                                        )
                                    })
                                }

                            </RadioGroup>
                        </ul>
                    </div>

                    <div className="filters pt-0">
                        <h5>Filter By Ratings</h5>
                        <ul>
                            <RadioGroup aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue="female"
                                        name="radio-buttons-group">
                                {
                                    ratingsArr.length!==0 &&
                                    ratingsArr.map((item,index)=>{
                                        return (
                                            <li key={index}><FormControlLabel value={item} control={<Radio onChange={()=>filterByRating(item)}/>}
                                                                  label={item}/>
                                            </li>
                                        )
                                    })
                                }

                            </RadioGroup>
                        </ul>
                    </div>
                    <div className="d-flex">
                        <Button className="btn btn-g"><FilterAltOutlinedIcon/> Filter</Button>
                    </div>
                </div>
                <br/>
                <img src={bannerImg} className="w-100"/>

            </div>
        </>
    );
}

export default Sidebar;