import React from 'react'
import './style.css'

import img1 from '../../../assets/images/thumbnail-1.jpg';
import img2 from '../../../assets/images/thumbnail-2.jpg';
import img3 from '../../../assets/images/thumbnail-3.jpg';
import {Link} from "react-router-dom";
import Rating from "@mui/material/Rating";
import {Button} from "@mui/material";
import ShoppingCartOutlined from "@mui/icons-material/ShoppingCartOutlined";

const TopProducts=(props)=>{
    return (
        <>
            <div className="topSelling_box">
                <h3>{props.title}</h3>
                <div className="items d-flex align-items-center">

                    <div className="img">
                        <Link to="">
                            <img src={img1} alt="" className="w-100"/>
                        </Link>
                    </div>
                    <div className="info px-3">
                        <Link to=""><h4>Nestle Original Coffee-Mate Coffee Creamer</h4></Link>
                        <Rating name="half-rating-read" defaultValue={3.5} precision={0.5} readOnly/>
                        <div className="d-flex align-items-center mt-3">
                            <div className="d-flex align-items-center">
                                <span className="price text-g fw-bold">$28.24</span> <span
                                className="oldPrice">$40.34</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="items d-flex align-items-center">

                    <div className="img">
                        <Link to="">
                            <img src={img1} alt="" className="w-100"/>
                        </Link>
                    </div>
                    <div className="info px-3">
                        <Link to=""><h4>Nestle Original Coffee-Mate Coffee Creamer</h4></Link>
                        <Rating name="half-rating-read" defaultValue={3.5} precision={0.5} readOnly/>
                        <div className="d-flex align-items-center mt-3">
                            <div className="d-flex align-items-center">
                                <span className="price text-g fw-bold">$28.24</span> <span
                                className="oldPrice">$40.34</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="items d-flex align-items-center">

                    <div className="img">
                        <Link to="">
                            <img src={img1} alt="" className="w-100"/>
                        </Link>
                    </div>
                    <div className="info px-3">
                        <Link to=""><h4>Nestle Original Coffee-Mate Coffee Creamer</h4></Link>
                        <Rating name="half-rating-read" defaultValue={3.5} precision={0.5} readOnly/>
                        <div className="d-flex align-items-center mt-3">
                            <div className="d-flex align-items-center">
                                <span className="price text-g fw-bold">$28.24</span> <span
                                className="oldPrice">$40.34</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="items d-flex align-items-center">

                    <div className="img">
                        <Link to="">
                            <img src={img1} alt="" className="w-100"/>
                        </Link>
                    </div>
                    <div className="info px-3">
                        <Link to=""><h4>Nestle Original Coffee-Mate Coffee Creamer</h4></Link>
                        <Rating name="half-rating-read" defaultValue={3.5} precision={0.5} readOnly/>
                        <div className="d-flex align-items-center mt-3">
                            <div className="d-flex align-items-center">
                                <span className="price text-g fw-bold">$28.24</span> <span
                                className="oldPrice">$40.34</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TopProducts;