import React, {useContext, useEffect, useState} from 'react';
import Slider from "react-slick";
import './index.css'
import {Link} from "react-router-dom";
import {MyContext} from "../../App";

const CatSlider = (props) => {
    const [allData, setAllData] = useState(props.data);
    const [totalLength,settotalLength]=useState([]);
    const context = useContext(MyContext);
    const [itemBg, setItemBg] = useState([
        '#fffceb',
        '#ecffec',
        '#feefea',
        '#fff3eb',
        '#fff3ff',
        '#f2fce4',
        '#feefea',
        '#fffceb',
        '#feefea',
        '#ecffec',
        '#feefea',
        '#fff3eb',
        '#fff3ff',

    ]);
    const catName = ['Cake & Milk', 'Organic Kiwi', 'Peach', 'Red Apple', 'Snack', 'Vegetables', 'Strawberry', 'Black plum', 'Custard apple', 'Coffee & Tea', 'Headphone'];
    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 10,
        slidesToScroll: 1,
        fade: false,
        arrows: context.windowWidth>992,
        autoplay: context.windowWidth>992?2000:false,
        centerMode: context.windowWidth>992
    };

    var catLength=0;
    var lengthArr=[];

    useEffect(() => {
        allData.length!==0 &&
            allData.map((item,index)=>{
                item.items.length!==0 &&
                    item.items.map((item_)=>{
                        catLength+=item_.products.length
                    })
                lengthArr.push(catLength)
                catLength=0;
            })

        const list=lengthArr.filter((item,index)=>lengthArr.indexOf(item)===index);
       settotalLength(list);
    }, []);
    return (
        <div className='catSliderSection'>
            <div className="container-fluid">
                <h2 className='hd'>Featured Categories</h2>
                <Slider {...settings} className='cat_slider_Main' id='cat_slider_Main'>

                    {
                        allData.length !== 0 &&
                        allData.map((item, index) => {
                            return(
                            <div className="item" key={index}>
                                <Link to={`/cat/${item.cat_name.toLowerCase()}`}>
                                <div className="info" style={{background:itemBg[index]}}>
                                    <img src={item.image} width='80'/>
                                    <h5 className='text-capitalize'>{item.cat_name}</h5>
                                    <p>{totalLength[index]} items</p>
                                </div>
                            </Link>
                            </div>
                            );
                        })

                    }
                    {/*{*/}
                    {/*    itemBg.length!==0 && itemBg.map((item, index) => {*/}
                    {/*        return (*/}
                    {/*            <div className="item" id={index}>*/}
                    {/*                <div className="info" style={{background:item}} id={index}>*/}
                    {/*                    <img src={'https://wp.alithemes.com/html/nest/demo/assets/imgs/shop/cat-'+(index+1)+'.png'} alt='Categories'/>*/}
                    {/*                    <h5>{catName[index]}</h5>*/}
                    {/*                    <p>26 items</p>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        )*/}
                    {/*    })*/}
                    {/*}*/}
                </Slider>
            </div>
        </div>
    )
}

export default CatSlider;