import React, {useContext, useEffect, useRef, useState} from 'react';
import '../header/header.css';
import Logo from '../../assets/images/logo.svg';
import Select from "../selectDrop/select";
import SearchIcon from '@mui/icons-material/Search';
import axios from "axios";
import IconCompare from '../../assets/images/icon-compare.svg';
import IconHeart from '../../assets/images/icon-heart.svg';
import IconCart from '../../assets/images/icon-cart.svg';
import IconUser from '../../assets/images/icon-user.svg';
import {Button, ClickAwayListener} from "@mui/material";
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import Nav from "./nav/nav";
import {Link, useNavigate} from "react-router-dom";
import {MyContext} from "../../App";
import MenuIcon from '@mui/icons-material/Menu';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';

const Header = (props) => {
    const context = useContext(MyContext);
    const history = useNavigate();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isOpenDropDown, setisOpenDropDown] = useState(false);
    const [isOpenAccDropDown, setisOpenAccDropDown] = useState(false);
    const headerRef = useRef();
    const [isOpenSearch, setOpenSearch] = useState(false);
    const [isOpenNav, setIsOpenNav] = useState(false);
    const searchInput = useRef();

    const [categories, setCategories] = useState([
        'Milks and Dairies',
        'Wines & Drinks',
        'Clothing & Beauty',
        'Fresh Seafood',
        'Pet Foods & Toy',
        'Fast Food'
    ]);

    const countryList = [];

    useEffect(() => {
        getCountry('https://countriesnow.space/api/v0.1/countries')
    }, []);
    const getCountry = async (url) => {
        try {
            await axios.get(url).then((res) => {
                if (res !== null) {
                    res.data.data.map((item, index) => {
                        countryList.push(item.country);
                    })

                }
            })
        } catch (error) {
            console.log(error.message);
        }
    }
    useEffect(() => {
        window.addEventListener("scroll", () => {
            let position = window.pageYOffset;
            if (position > 100) {
                headerRef.current.classList.add('fixed');
            } else {
                headerRef.current.classList.remove('fixed');
            }
        })
    }, [])

    const signOut = () => {
        context.signOut();
        history('/');
    }

    const openSearch = () => {
        setOpenSearch(true);
        searchInput.current.focus();
    }

    const closeSearch = () => {
        setOpenSearch(false);
        searchInput.current.blur();
        searchInput.current.value = "";
    }

    const openNav = () => {
        setIsOpenNav(true);
    }

    const closeNav = () => {
        setIsOpenNav(false);
        setisOpenAccDropDown(false);
    }
    return (
        <>
            <div className="headerWrapper" ref={headerRef}>
                <header>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-sm-2 part1 d-flex align-items-center'>
                                <Link to="/"><img src={Logo} className='logo' alt='logo'/></Link>
                                {
                                    windowWidth < 992 &&

                                    <div className="ms-auto d-flex align-items-center">

                                        <div className="navbarToggle me-0" onClick={openSearch}><SearchIcon/></div>
                                        <ul className='list list-inline mb-0 headerTabs ps-0 me-4'>
                                            <li className='list-inline-item'>

                                                <span>
                                                    <Link to={'/cart'}> <img src={IconCart}/>
                                                        <span className='badge bg-success rounded-circle'>
                                                          {props.cartItemsCount}
                                                        </span>
                                                       </Link>
                                                </span>


                                            </li>

                                        </ul>
                                        <div className="navbarToggle me-2" onClick={openNav}><MenuIcon/></div>
                                        {
                                            context.isLogin === "true" &&
                                            <div className="myAccDrop"
                                                 onClick={() => setisOpenAccDropDown(!isOpenAccDropDown)}>
                                                <PersonOutlineOutlinedIcon/></div>
                                        }
                                    </div>
                                }
                            </div>
                            <div className="col-sm-5 part2">
                                <div
                                    className={`headerSearch d-flex align-items-center ${isOpenSearch === true ? 'open' : 'false'}`}>
                                    {/*{*/}
                                    {/*    windowWidth < 992 &&*/}
                                    {/*    <div className="countryWrapper mb-4 w-100">*/}
                                    {/*        <Select data={countryList} placeholder={'Your Location'}*/}
                                    {/*                icon={<SearchIcon style={{opacity: '0.5'}}/>}/>*/}
                                    {/*    </div>*/}
                                    {/*}*/}

                                    {
                                        windowWidth < 992 &&
                                        <div className="closeSearch" onClick={closeSearch}><ArrowBackIosIcon/></div>
                                    }
                                    <Select data={categories} placeholder={'All Categories'} icon={false}/>
                                    <div className="search">
                                        <input type="text" placeholder="Search for items..." ref={searchInput}/>
                                        <SearchIcon className='searchIcon cursor'/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-5 part3 d-flex align-items-center res-hide">
                                <div className="ml-auto d-flex align-items-center">
                                    <div className="countryWrapper">
                                        <Select data={countryList} placeholder={'Your Location'}
                                                icon={<SearchIcon style={{opacity: '0.5'}}/>}/>
                                    </div>
                                    <ClickAwayListener onClickAway={() => setisOpenDropDown(false)}>

                                        <ul className='list list-inline mb-0 headerTabs ps-0 me-4'>
                                            <li className='list-inline-item'>
                                                <span>
                                                    <img src={IconCompare}/>
                                                    <span className='badge bg-success rounded-circle'>3</span>
                                                    Compare
                                                </span>
                                            </li>
                                            <li className='list-inline-item'>
                                                <span>
                                                    <img src={IconHeart}/>
                                                    <span className='badge bg-success rounded-circle'>3</span>
                                                    Wishlist
                                                </span>
                                            </li>
                                            <li className='list-inline-item'>

                                                <span>
                                                    <Link to={'/cart'}> <img src={IconCart}/>
                                                        <span className='badge bg-success rounded-circle'>
                                                          {props.cartItemsCount}
                                                        </span>
                                                        Cart</Link>
                                                </span>


                                            </li>
                                            {
                                                context.isLogin === "true" ?
                                                    <li className='list-inline-item'>

                                                        <span onClick={() => setisOpenDropDown(!isOpenDropDown)}>
                                                            <img src={IconUser}/>
                                                            Account
                                                        </span>
                                                        {
                                                            isOpenDropDown !== false &&
                                                            <ul className='dropdownMenu'>
                                                                <li><Button
                                                                    className='align-items-center'><Person2OutlinedIcon/> My
                                                                    Account</Button></li>
                                                                <li><Button><Person2OutlinedIcon/> Order
                                                                    Tracking</Button></li>
                                                                <li><Button><FavoriteBorderOutlinedIcon/> My
                                                                    Wishlist</Button>
                                                                </li>
                                                                <li><Button><SettingsOutlinedIcon/> Setting</Button>
                                                                </li>
                                                                <li><Button onClick={signOut}><LogoutOutlinedIcon/> Sign
                                                                    Out</Button></li>
                                                            </ul>
                                                        }


                                                    </li>
                                                    :

                                                    <li className="list-inline-item">
                                                        <Link to={'/signIn'}>
                                                            <Button className="btn btn-g">Sign In</Button>
                                                        </Link>
                                                    </li>
                                            }


                                        </ul>
                                    </ClickAwayListener>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <Nav data={props.data} openNav={isOpenNav} closeNav={closeNav}/>
            </div>

            <div className="afterHeader">

            </div>
            {
                isOpenAccDropDown !== false &&
                <>
                    <div className="navbarOverlay" onClick={closeNav}></div>
                    <ul className='dropdownMenu dropdownMenuAcc' onClick={closeNav}>
                        <li><Button
                            className='align-items-center'><Link to=""><Person2OutlinedIcon/> My
                            Account</Link></Button>
                        </li>
                        <li><Button
                            className='align-items-center'><Link to=""> <img src={IconCompare}/> Compare</Link></Button>
                        </li>
                        <li><Button
                            className='align-items-center'><Link to=""> <img src={IconCart}/> Cart</Link></Button>
                        </li>

                        <li><Button><Link to=""><Person2OutlinedIcon/> Order Tracking</Link></Button></li>
                        <li><Button><Link to=""><FavoriteBorderOutlinedIcon/> My Wishlist</Link></Button>
                        </li>
                        <li><Button><Link to=""><SettingsOutlinedIcon/> Setting</Link></Button></li>
                        <li><Button onClick={signOut}><Link to=""><LogoutOutlinedIcon/> Sign Out</Link></Button></li>
                    </ul>
                </>
            }
        </>
    )
}
export default Header;