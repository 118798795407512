import React, {useEffect, useState} from 'react';
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const QuantityBox = (props) => {
    const [inputValue, setInputValue] = useState(1);
    const [cartItems, setCartItems] = useState([]);

    useEffect(() => {
        setCartItems(props.cartItems);
    }, [props.cartItems]);
    // const plus = () => {
    //     setInputValue(inputValue + 1);
    // }
    //
    // const minus = () => {
    //     if (inputValue != 0) {
    //         setInputValue(inputValue - 1);
    //     }
    // }

    const updateCart = (items) => {
        props.updateCart(items);
    }
    return (

        <div className="addCartSection pt-4 pb-4 d-flex align-items-center">
            <div className="counterSec me-3">
                <input type="number" value={inputValue}/>
                <span className="arrow plus"
                      onClick={() => {
                          setInputValue(inputValue + 1);
                          const _cart = props.cartItems?.map((cartItem, key) => {
                              return key === parseInt(props.index) ? {
                                  ...cartItem,
                                  quantity: inputValue + 1
                              } : {...cartItem}
                          });
                          updateCart(_cart);
                          setCartItems(_cart);
                      }
                      }><KeyboardArrowUpIcon/></span>
                <span className="arrow minus"
                      onClick={() => {
                          if (inputValue !== 1) {
                              setInputValue(inputValue - 1);
                          }
                          const _cart = props.cartItems?.map((cartItem, key) => {
                              return key === parseInt(props.index) ? {
                                  ...cartItem,
                                  quantity: cartItem.quantity !== 1 ? inputValue - 1 : cartItem.quantity
                              } : {...cartItem}
                          });
                          updateCart(_cart);
                          setCartItems(_cart);
                      }
                      }><KeyboardArrowDownIcon/></span>
            </div>
        </div>

    )
}

export default QuantityBox;