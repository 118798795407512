import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import './style.css';
import TextField from '@mui/material/TextField';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import {Button} from "@mui/material";
import GoogleImg from '../../assets/images/google.png';
import {signInWithEmailAndPassword, getAuth, GoogleAuthProvider , signInWithPopup } from "firebase/auth";
import {useNavigate} from 'react-router-dom';
import {app} from "../../firebase";
import {useContext} from "react";
import {MyContext} from "../../App";
const googleProvider = new GoogleAuthProvider();
const SignIn=()=>{
    const [showPassword, setShowPassword] = useState(false);
    const [showLoader,setShowLoader]=useState(false);
    const [formFields,setFormFields]=useState({
        email:'',
        password:''
    })
    const auth=getAuth(app);
    const history=useNavigate();
    const context = useContext(MyContext);
    const signIn=()=>{
        setShowLoader(true);
        signInWithEmailAndPassword(auth,formFields.email,formFields.password)
            .then((userCredential)=>{
                //Signed Up
                const user=userCredential.user;
                setShowLoader(false);
                setFormFields({
                    email:'',
                    password:'',

                })
                localStorage.setItem('isLogin',true);
                context.signIn();
                history('/')
            })
            .catch((error)=>{
                const errorCode=error.code;
                const errorMessage=error.message;
                console.log(errorCode,errorMessage)
                alert(errorMessage);
                setShowLoader(false);
            })
    }

    const signInWithGoogle=()=>{
        setShowLoader(true);
        signInWithPopup(auth, googleProvider)
            .then((result) => {

                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                // The signed-in user info.
                const user = result.user;

                setShowLoader(false);


                localStorage.setItem('isLogin',true);
                context.signIn();

                history('/');

            }).catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.customData.email;
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);
            // ...
        });
    }
    const onChangeField=(e)=>{
        const name=e.target.name;
        const value=e.target.value;

        setFormFields(()=>({
            ...formFields,
            [name]:value,
        }))
    }

    return (
        <>
            <section className='signIn mb-5'>
                <div className="breadcrumbWrapper">
                    <div className="container-fluid">
                        <ul className="breadcrumb breadcrumb2 mb-0">
                            <li><Link to="/">Home</Link></li>
                            <li>Sign In</li>
                        </ul>
                    </div>
                </div>


                <div className='loginWrapper'>
                    <div className='card shadow'>

                        <Backdrop
                            sx={{color:'#fff',zIndex:(theme)=>theme.zIndex.drawer+1}}
                            open={showLoader}
                            className="formLoader"
                        >
                            <CircularProgress color="inherit"/>
                        </Backdrop>

                        <h3>Sign In</h3>
                        <form className='mt-4'>
                            <div className='form-group mb-4 w-100'>
                                <TextField id="email" type="email" name='email' label="Email" className='w-100'
                                           onChange={onChangeField} value={formFields.email}
                                          />
                            </div>
                            <div className='form-group mb-4 w-100'>
                                <div className='position-relative'>
                                    <TextField id="password" type={showPassword===false?'password':'text'}
                                               name='password' label="Password" className='w-100'
                                               onChange={onChangeField} value={formFields.password}
                                             />
                                    <Button className='icon' onClick={()=>setShowPassword(!showPassword)}>
                                        {
                                            showPassword===false ?
                                            <VisibilityOffOutlinedIcon/>:
                                                <VisibilityOutlinedIcon/>
                                        }


                                    </Button>
                                </div>
                            </div>


                            <div className='form-group mt-5 mb-4 w-100'>
                                <Button className='btn btn-g btn-lg w-100' onClick={signIn}>Sign In</Button>
                            </div>


                            <div className='form-group mt-5 mb-4 w-100 signInOr'>
                                <p className='text-center'>OR</p>
                                <Button className='w-100' variant="outlined" onClick={signInWithGoogle}><img src={GoogleImg}/>
                                    Sign In with Google</Button>
                            </div>


                            <p className='text-center'>Not have an account
                                <b> <Link to="/signup">Sign Up</Link>
                                </b>
                            </p>

                        </form>
                    </div>
                </div>


            </section>
        </>
    );
}

export default SignIn;