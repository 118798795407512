import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import Product from "../../components/product";
import {Button} from "@mui/material";
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';

const Listing = (props) => {
    const [isOpenDropDown, setisOpenDropDown] = useState(false);
    const [isOpenDropDown2, setisOpenDropDown2] = useState(false);

    const [data, setData] = useState([]);
    let { id} = useParams();
    const itemsData = [];
    useEffect(() => {
        props.data.length !== 0 &&
        props.data.map((item) => {
            //page=single cat
            if (props.single === true) {
                if (item.cat_name.toLowerCase() == id.toLowerCase()) {
                    item.items.length !== 0 &&

                    item.items.map((item_) => {
                        item_.products.map((item__, index__) => {
                            itemsData.push({ ...item__, parentCatName: item.cat_name, subCatName: item_.cat_name })
                        })
                    })
                }
            }

            //page=double cat
            else {
                item.items.length !== 0 &&
                item.items.map((item_) => {
                    if (item_.cat_name.split(' ').join('-').toLowerCase() == id.split(' ').join('-').toLowerCase()) {
                        item_.products.map((item__, index__) => {

                            itemsData.push({ ...item__, parentCatName: item.cat_name, subCatName: item_.cat_name })

                        })
                    }
                })
            }
        })
        const list2 = itemsData.filter((item, index) => itemsData.indexOf(item) === index);
        setData(list2);
    }, [id]);

    const filterByPrice=(minValue,maxValue)=>{
        props.data.length!==0 &&
            props.data.map((item)=>{
                if(props.single===true){
                    if(id===item.cat_name.toLowerCase()){
                        item.items.length!==0 &&
                            item.items.map((item_)=>{

                                item_.products.length!==0 &&
                                    item_.products.map((product)=>{
                                        let price=parseInt(product.price.toString().replace(/,/g,""));
                                        if(minValue<=price && maxValue>=price){

                                                itemsData.push({...product});

                                        }

                                    })
                            })
                    }
                }
                else {

                        item.items.length!==0 &&
                        item.items.map((item_)=>{
                        if(item_.cat_name.split(' ').join('-').toLowerCase()==id.split(' ').join('-').toLowerCase()) {

                            item_.products.map((product) => {
                                let price = parseInt(product.price.toString().replace(/,/g, ""));
                                if (minValue <= price && maxValue >= price) {

                                    itemsData.push({...product});

                                }

                            })
                        }
                        })
                    }

            })
        const list2=itemsData.filter((item,index)=>itemsData.indexOf(item)===index);
        setData(list2);
    }

    const filterByBrand = (keyword) => {

        props.data.length !== 0 &&
        props.data.map((item, index) => {

            //page == single cat
            if (props.single === true) {

                item.items.length !== 0 &&
                item.items.map((item_) => {
                    item_.products.map((item__, index__) => {
                        if (item__.brand.toLowerCase() === keyword.toLowerCase()) {
                            //console.log(item__)
                            itemsData.push({ ...item__, parentCatName: item.cat_name, subCatName: item_.cat_name })
                        }


                    })

                })


            }
            //page == double cat
            else {
                item.items.length !== 0 &&
                item.items.map((item_, index_) => {
                    // console.log(item_.cat_name.replace(/[^A-Za-z]/g,"-").toLowerCase())
                    if (item_.cat_name.split(' ').join('-').toLowerCase() == id.split(' ').join('-').toLowerCase()) {
                        item_.products.map((item__, index__) => {
                            if (item__.brand.toLowerCase() === keyword.toLowerCase()) {
                                itemsData.push({ ...item__, parentCatName: item.cat_name, subCatName: item_.cat_name })
                            }

                        })

                    }
                })
            }

        })



        const list2 = itemsData.filter((item, index) => itemsData.indexOf(item) === index);
        //console.log(itemsData)


        setData(list2);

        window.scrollTo(0, 0)

    }
    const filterByRating = (keyword) => {
        props.data.length!==0 &&
        props.data.map((item)=>{
            if(props.single===true){

                item.items.length!==0 &&
                item.items.map((item_)=>{
                    item_.products.map((item__,index__)=>{
                        let rating=parseFloat(item__.rating);
                        if(rating===keyword){
                            itemsData.push({...item__,parentCatName:item.cat_name,subCatName:item_.cat_name})
                        }

                    })
                })

            }
            else {

                item.items.length!==0 &&
                item.items.map((item_)=>{
                    if(item_.cat_name.split(' ').join('-').toLowerCase()==id.split(' ').join('-').toLowerCase()) {

                        item_.products.map((item__,index__) => {
                            let rating=parseFloat(item__.rating);
                            if(rating===keyword){
                                itemsData.push({...item__,parentCatName:item.cat_name,subCatName:item_.cat_name})
                            }


                        })
                    }
                })
            }

        })
        const list2=itemsData.filter((item,index)=>itemsData.indexOf(item)===index);
        setData(list2);
        window.scrollTo(0, 0)

    }
    return (
        <>
            <section className="listingPage">
                <div className="container-fluid">
                    <div className="breadcrumb flex-column">
                        <h1 className='text-capitalize'>{sessionStorage.getItem('cat')}</h1>
                        <ul className="list list-inline mb-0">
                            <li className="list-inline-item">
                                <Link to={'/'}>Home</Link>
                            </li>

                                <li className="list-inline-item">
                                    <Link to={`/cat/${sessionStorage.getItem('cat')}`} className='text-capitalize'>{sessionStorage.getItem('cat')}</Link>
                                </li>

                            {
                                props.single === false &&
                                <li className="list-inline-item">
                                   {id}
                                </li>
                            }


                        </ul>
                    </div>

                    <div className="listingData">
                        <div className="row">
                            <div className="col-md-3 sidebarWrapper">
                                {
                                  <Sidebar data={props.data} currentCatData={data} filterByPrice={filterByPrice}
                                  filterByBrand={filterByBrand} filterByRating={filterByRating}/>
                                }
                            </div>
                            <div className="col-md-9 rightContent homeProducts pt-0">
                                <div className="topStrip d-flex align-items-center">
                                    <p className="mb-0">We found <span className="text-success">{data.length}</span> items for you!
                                    </p>
                                    <div className="ms-auto d-flex align-items-center">
                                        <div className="tab_ position-relative">
                                            <Button className='btn_' onClick={() => setisOpenDropDown(!isOpenDropDown)}><GridViewOutlinedIcon/> Show:
                                                50</Button>
                                            {
                                                isOpenDropDown !== false &&

                                                <ul className='dropdownMenu'>
                                                    <li><Button className='align-items-center'
                                                                onClick={() => setisOpenDropDown(false)}>50</Button>
                                                    </li>
                                                    <li><Button onClick={() => setisOpenDropDown(false)}> 100</Button>
                                                    </li>
                                                    <li><Button onClick={() => setisOpenDropDown(false)}> 150</Button>
                                                    </li>
                                                    <li><Button onClick={() => setisOpenDropDown(false)}> 200</Button>
                                                    </li>
                                                    <li><Button onClick={() => setisOpenDropDown(false)}> All</Button>
                                                    </li>

                                                </ul>
                                            }
                                        </div>
                                        <div className="tab_ ms-3 position-relative">
                                            <Button className='btn_'
                                                    onClick={() => setisOpenDropDown2(!isOpenDropDown2)}><FilterListOutlinedIcon/> Sort
                                                by: Featured</Button>
                                            {
                                                isOpenDropDown2 !== false &&

                                                <ul className='dropdownMenu'>
                                                    <li><Button onClick={() => setisOpenDropDown2(false)}
                                                                className='align-items-center'> Featured</Button></li>
                                                    <li><Button onClick={() => setisOpenDropDown2(false)}>Price: Low to
                                                        High</Button></li>
                                                    <li><Button onClick={() => setisOpenDropDown2(false)}>Price: High to
                                                        Low</Button></li>
                                                    <li><Button onClick={() => setisOpenDropDown2(false)}>Price: Release
                                                        Date</Button></li>
                                                    <li><Button onClick={() => setisOpenDropDown2(false)}>Price: Avg.
                                                        Rating</Button></li>

                                                </ul>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="productRow ps-4 pe-3">
                                    {
                                        data.length !== 0 &&
                                        data.map((item, index) => {
                                            return (
                                                <div className="item" key={index}>
                                                    <Product tag={item.type} item={item}/>
                                                </div>
                                            );
                                        })
                                    }


                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Listing;